/* eslint-disable check-file/filename-naming-convention */

import type { SsrProps } from '@/core/features/app/ssr-props';
import type { GetServerSideProps } from 'next';

import React from 'react';

import DynamicPageContainer from '@/features/a-dynamic-page/components/dynamic-page-container';

// In Nextjs each page has to export its own server side props handler
// this handler will be reused in pages/index.ts
// ts-prune-ignore-next
export const getServerSideProps: GetServerSideProps = async (context) => {
    const { getDynamicPageServerSideProps } = await import(
        '@/core/features/a-dynamic-page/dynamic-page-next-ssr-props'
    );

    return getDynamicPageServerSideProps(context);
};

export default function DynamicPageNext(props: SsrProps) {
    return <DynamicPageContainer {...props} />;
}
